import React, { createContext, useReducer } from "react"
import PropTypes from "prop-types"

export const GlobalDispatchContext = createContext()
export const GlobalStateContext = createContext()

const initialGlobalState = {
  dialogs: {
    hailMary: false,
    ourFather: false,
    gloryBe: false,
  },
}

function contextReducer(state, action) {
  const type = action.type
  switch (type) {
    case "DIALOG_TOGGLED":
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.key]: !state.dialogs[action.key],
        },
      }
    default:
      return {
        ...state,
      }
  }
}

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(contextReducer, initialGlobalState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

GlobalContextProvider.propTypes = {
  children: PropTypes.any,
}

export default GlobalContextProvider
